import { createFunction } from '../utilities/language.js';

import * as buttonUtilities from './utilities.js';

export default class Button {

  constructor(configuration, page) {

    this.configuration = configuration;
    this.page = page;

  }

  initialize() {

    buttonUtilities.addStyleBlock(this.configuration);

    this.button = document.getElementById(this.configuration.id);

    this.button.addEventListener('click', this.handleButtonClick.bind(this));

  }

  handleButtonClick(event) {

    event.preventDefault();

    const func = createFunction(this.configuration.clickHandler, ['page', 'button', 'event'], () => window.location.href = this.configuration.url);

    func(this.page, this, event);

  }

}