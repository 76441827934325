export function getStyleString(configuration) {

  const style = `

    #${configuration.id}:hover {
        background-color: ${configuration.hoverBackgroundColor};
        color: ${configuration.hoverTextColor};
      }
  `;

  return style;

}

function getStyleBlockId(configuration) {

  return `${configuration.id}-style`;

}

export function deleteStyleBlock(configuration) {

  const styleBlock = document.getElementById(getStyleBlockId(configuration));

  styleBlock.parentNode.removeChild(styleBlock);

}

export function addStyleBlock(configuration) {

  const styleBlockId = getStyleBlockId(configuration);

  document.head.insertAdjacentHTML('beforeend', `<style id="${styleBlockId}">${getStyleString(configuration)}</style>`);

}

export function updateStyleBlock(configuration) {

  deleteStyleBlock(configuration);

  addStyleBlock(configuration);

}